<template>
  <dl
    class="m-0"
    :class="{
      'pl-3': !icon && keepIconPadding,
      'd-inline': inline,
    }"
  >
    <dd
      :class="[
        'm-0',
        {
          'd-inline mr-2': inline,
          'd-flex align-items-baseline': !inline,
        },
      ]"
    >
      <slot v-if="$slots.icon || $scopedSlots.icon" name="icon" />
      <i v-else-if="icon" class="text-orange mr-1 fa-fw" :class="icon" />

      <span :class="labelClass">
        <slot
          v-if="$slots.label || $scopedSlots.label"
          name="label"
          :placeholder="labelPlaceholder"
        />
        <span v-else-if="label" class="flex-wrap">
          {{ label || labelPlaceholder }}
        </span>
        <span v-else-if="labelPlaceholder">{{ labelPlaceholder }}</span>
        <span v-else>&nbsp;</span>
      </span>
    </dd>
    <dd class="m-0" :class="{ 'pl-4': icon, 'd-inline': inline }">
      <span :class="textClass">
        <slot
          v-if="$slots.default || $scopedSlots.default"
          :placeholder="textPlaceholder"
        />
        <span v-else-if="text">
          {{ text }}
        </span>
        <span v-else-if="textPlaceholder">{{ textPlaceholder }}</span>
        <span v-else>&nbsp;</span>
      </span>
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'UiDetailDisplay',
  props: {
    icon: { type: String, default: '' },

    label: { type: [String, Number], default: '' },
    labelClass: { type: String, default: 'text-muted' },
    labelPlaceholder: { type: [String, Number, Boolean], default: '-' },

    text: { type: [String, Number], default: '' },
    textClass: { type: String, default: 'font-weight-light' },
    textPlaceholder: { type: [String, Number, Boolean], default: '-' },

    inline: { type: Boolean, default: false },
    keepIconPadding: { type: Boolean, default: false },
  },
};
</script>

<style></style>

import { http } from '@/core/api';

class PeiApi {
  async getHistory() {
    try {
      const { data } = await http.get('/api/pei/history');
      return data;
    } catch {
      return null;
    }
  }

  async getEvaluations(studentSieId, page = 1, size = 10) {
    try {
      const { data } = await http.get(
        `/api/pei/evaluations/${studentSieId}?pageNumber=${page}&pageSize=${size}`
      );
      return data;
    } catch {
      return null;
    }
  }

  async getControversy(peiId) {
    try {
      const { data } = await http.get(`/api/pei/${peiId}/controversies`);
      return data;
    } catch {
      return null;
    }
  }

  async downloadStudentDocument(studentEvaluationId, documentTypeId = 1) {
    try {
      const { data } = await http.get(
        `/api/pei/evaluations/${studentEvaluationId}/document/${documentTypeId}`
      );
      return data;
    } catch {
      return null;
    }
  }
}

export { PeiApi };

<template>
  <div v-if="studentSieId">
    <SectionTitle has-line title="Evaluaciones Realizadas" />

    <template v-for="(item, j) in groupedByDiscipline">
      <div :key="j" class="mt-3">
        <div
          class="btn btn-light rounded-pill border-0 mb-3"
          :style="{ 'background-color': '#F5EEE9', cursor: 'default' }"
        >
          <b-avatar size="1.6rem" :style="{ 'background-color': '#FF7058' }">
            <em class="fas fa-file-user" />
          </b-avatar>
          <span class="ml-2 font-weight-bolder" v-text="item.disciplineName" />
        </div>

        <template v-for="(evaluation, i) in item.evaluations">
          <UiCardListItem
            :key="i"
            border-indicator
            :border-indicator-color="getColor(evaluation.determinationStatus)"
            :class="{ 'mt-3': i > 0 }"
            collapse
          >
            <MatchMedia v-slot="{ mobile }">
              <b-row>
                <b-col
                  class="pb-4 pb-md-0"
                  :class="mobile ? 'border-bottom' : 'border-right'"
                  cols="12"
                  md="2"
                >
                  <div class="d-flex align-items-center h-100">
                    <UiBadge
                      :color-hex="getColor(evaluation.determinationStatus)"
                      :text="evaluation.determinationStatus"
                      text-class="text-dark text-wrap"
                    />
                  </div>
                </b-col>
                <b-col class="mt-4 mt-md-0" cols="12" md>
                  <UiDetailDisplay
                    label="Evaluación:"
                    :text="evaluation.disciplineTypeName"
                  />
                </b-col>
                <b-col class="mt-4 mt-md-0" cols="12" md>
                  <UiDetailDisplay label="Fecha:">
                    <template #default="{ placeholder }">
                      <span v-if="evaluation.evaluationDate">
                        {{ evaluation.evaluationDate | moment('DD/MMM/YYYY') }}
                      </span>
                      <span v-else>
                        {{ placeholder }}
                      </span>
                    </template>
                  </UiDetailDisplay>
                </b-col>
                <b-col class="mt-4 mt-md-0" cols="12" md>
                  <div
                    class="d-flex align-items-center justify-content-end h-100"
                  >
                    <b-button
                      :block="mobile"
                      outlined
                      variant="outline-success"
                      @click="getDocument(evaluation)"
                      v-if="evaluation.hasDocument"
                    >
                      <em class="fas fa-file-search fa-fw" />
                      Ver Documento
                    </b-button>
                     <b-button
                      :block="mobile"
                      outlined
                      variant="outline-danger"
                      v-if="!evaluation.hasDocument"
                    >
                      <em class="fas fa-ban fa-fw" />
                      No existe documento
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </MatchMedia>

            <template #collapse>
              <b-card class="bg-light-gray border-0 p-2" no-body>
                <b-card class="bg-white border-0 p-3" no-body>
                  <b-row>
                    <b-col class="pt-4 pt-lg-0" cols="12" md="6">
                      <UiDetailDisplay
                        label="Administrada por:"
                        :text="
                          computedText(evaluation.evaluationAdministeredBy)
                        "
                      />
                    </b-col>
                    <b-col class="pt-4 pt-lg-0" cols="12" md="6">
                      <UiDetailDisplay
                        label="Número de Cita:"
                        :text="computedText(evaluation.formatedAppointmentId)"
                      />
                    </b-col>
                    <b-col class="pt-4 pt-lg-3" cols="12">
                      <UiDetailDisplay
                        label="Proveedor:"
                        :text="computedText(evaluation.providerName)"
                      />
                    </b-col>

                    <b-col class="pt-4 pt-lg-3" cols="12">
                      <UiDetailDisplay
                        label="Comentario:"
                        :text="computedText(evaluation.evaluationStatusComment)"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-card>
            </template>
          </UiCardListItem>
        </template>
      </div>
    </template>

    <vue-good-table
      class="mt-4"
      :columns="tableConfig.columns"
      mode="remote"
      :pagination-options="{
        enabled: true,
      }"
      :rows="items"
      :sort-options="{
        enabled: false,
      }"
      :total-rows="tableConfig.totalRecords"
      @on-column-filter="onColumnFilter"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      @on-sort-change="onSortChange"
    >
      <template #emptystate>
        El estudiante no cuenta con evaluaciones en sistema.
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <customPager
          ref="historyPager"
          :page-changed="props.pageChanged"
          :per-page="tableConfig.serverParams.perPage"
          :per-page-changed="props.perPageChanged"
          :total="props.total"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiBadge from '@/components/ui/UiBadge';
import SectionTitle from 'SectionTitle';
import { MatchMedia } from 'vue-component-media-queries';
import UiCardListItem from '@/components/ui/UiCardListItem';
import { downloadBlobToFile } from '@/utils/blob';
import { PeiApi } from '@/api/pei.api';
import alert from 'utils/mixins/alert.mixin';
import { DoesntApply } from '@/utils/filters';

const peiApi = new PeiApi();

const NO_STATUS_DISCIPLINE_ENUM = {
  22: 'Historial Desarrollo',
  23: 'Evaluaciones Educativas',
  200: 'Evaluación Intereses Vocacionales',
};

export default {
  name: 'StudentEvaluationsHistory',
  components: {
    UiDetailDisplay,
    SectionTitle,
    UiBadge,
    UiCardListItem,
    MatchMedia,
  },
  mixins: [alert],
  props: { studentSieId: { type: [String, Number], default: null } },
  data: () => ({
    items: [],
    tableConfig: {
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
      columns: [],
    },
    NO_STATUS_DISCIPLINE_ENUM,
  }),
  computed: {
    computedText() {
      return (text) => DoesntApply(text);
    },
    groupedByDiscipline() {
      return this.items?.reduce((sum, item) => {
        if (sum[item.disciplineName]) {
          sum[item.disciplineName].evaluations.push(item);
          return sum;
        }
        return {
          ...sum,
          [item.disciplineName]: {
            disciplineName: item.disciplineName,
            evaluations: [item],
          },
        };
      }, {});
    },
  },
  methods: {
    async getEvaluationsHistory() {
      const response = await peiApi.getEvaluations(
        this.studentSieId,
        this.tableConfig.serverParams.page,
        this.tableConfig.serverParams.perPage
      );
      if (response) {
        this.items = response.data?.map((x) => {
          if (NO_STATUS_DISCIPLINE_ENUM[x.disciplineId]) {
            x.determinationStatus = 'Aceptada';
          }
          return x;
        });
        this.tableConfig.totalPages = response.pageCount;
        this.tableConfig.totalRecords = response.totalItemCount;
      }
    },
    async getDocument(evaluation) {
      if (!evaluation.hasDocument) {
        this.ShowToast('Alerta', 'No existe documento.', 'error', 5000);
        return;
      }
      const data = await peiApi.downloadStudentDocument(
        evaluation.evaluationId
      );
      if (data) {
        downloadBlobToFile(data);
      }
    },
    getColor(determinationStatus) {
      switch (determinationStatus) {
        case 'Aceptada':
          return '#a4e5b2';

        case 'Rechazada':
          return '#d5b89a';

        case 'Pendiente':
          return '#f8eb7f';

        default:
          return '#00000029';
      }
    },
    updateParams(newProps) {
      this.tableConfig.serverParams = Object.assign(
        {},
        this.tableConfig.serverParams,
        newProps
      );
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getEvaluationsHistory();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getEvaluationsHistory();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.getEvaluationsHistory();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.getEvaluationsHistory();
    },
  },
  async created() {
    await this.getEvaluationsHistory();
  },
};
</script>

<template>
  <h5 class="m-0">
    <b-badge
      class="px-4 py-2"
      :class="cssClass"
      :pill="pill"
      :style="colorHex ? { 'background-color': colorHex } : {}"
      :variant="colorHex ? '' : color"
    >
      <slot v-if="$slots.icon || $scopedSlots.icon" name="icon" />
      <i v-else-if="icon" class="text-orange mr-1 fa-fw" :class="icon" />

      <slot
        v-if="$slots.default || $scopedSlots.default"
        :placeholder="textPlaceholder"
      />
      <span v-else-if="text" :class="textClass">
        {{ text }}
      </span>
      <span v-else-if="textPlaceholder">{{ textPlaceholder }}</span>
    </b-badge>
  </h5>
</template>

<script>
export default {
  name: 'UiBadge',
  props: {
    icon: { type: String, default: '' },

    text: { type: [String, Number], default: '' },
    textClass: { type: String, default: '' },
    textPlaceholder: { type: [String, Number, Boolean], default: '-' },

    color: { type: String, default: '' },
    colorHex: { type: String, default: '' },

    pill: { type: Boolean, default: true },

    cssClass: { type: [String, Object, Array], default: '' },
  },
};
</script>

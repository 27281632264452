var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dl',{staticClass:"m-0",class:{
    'pl-3': !_vm.icon && _vm.keepIconPadding,
    'd-inline': _vm.inline,
  }},[_c('dd',{class:[
      'm-0',
      {
        'd-inline mr-2': _vm.inline,
        'd-flex align-items-baseline': !_vm.inline,
      } ]},[(_vm.$slots.icon || _vm.$scopedSlots.icon)?_vm._t("icon"):(_vm.icon)?_c('i',{staticClass:"text-orange mr-1 fa-fw",class:_vm.icon}):_vm._e(),_c('span',{class:_vm.labelClass},[(_vm.$slots.label || _vm.$scopedSlots.label)?_vm._t("label",null,{"placeholder":_vm.labelPlaceholder}):(_vm.label)?_c('span',{staticClass:"flex-wrap"},[_vm._v(" "+_vm._s(_vm.label || _vm.labelPlaceholder)+" ")]):(_vm.labelPlaceholder)?_c('span',[_vm._v(_vm._s(_vm.labelPlaceholder))]):_c('span',[_vm._v(" ")])],2)],2),_c('dd',{staticClass:"m-0",class:{ 'pl-4': _vm.icon, 'd-inline': _vm.inline }},[_c('span',{class:_vm.textClass},[(_vm.$slots.default || _vm.$scopedSlots.default)?_vm._t("default",null,{"placeholder":_vm.textPlaceholder}):(_vm.text)?_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")]):(_vm.textPlaceholder)?_c('span',[_vm._v(_vm._s(_vm.textPlaceholder))]):_c('span',[_vm._v(" ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }
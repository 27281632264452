<template>
  <div>
    <MatchMedia v-slot="{ mobile }">
      <b-card
        class="ui-card-list-item bg-gray border-0"
        :class="[
          {
            'ui-card-list-item--border-indicator': borderIndicator,
            'ui-card-list-item--collapse': collapse,
            'ui-card-list-item--mobile': mobile,
            'ui-card-list-item--bordered': borderedDesign,
          },
        ]"
        :no-body="borderedDesign"
        :style="{
          'border-color': borderIndicator
            ? `${borderIndicatorColor} !important`
            : 'none',
        }"
      >
        <div
          v-if="collapse"
          class="ui-card-list-item--collapse__toggler"
          @click="visible = !visible"
        >
          <div
            class="ui-card-list-item--collapse__toggler__caret"
            :class="{ 'bg-white': borderedDesign }"
          >
            <i
              :class="[
                'far',
                `fa-${
                  visible
                    ? `chevron-circle-${mobile ? 'up' : 'down'}`
                    : `chevron-circle-${mobile ? 'down' : 'right'}`
                }`,
              ]"
              :style="{ color: visible ? '#2db782' : '#ff7058' }"
            />
          </div>
        </div>

        <b-card
          class="ui-card-list-item__container border-0"
          :class="[borderedDesign ? 'bg-white p-3' : 'bg-transparent']"
          no-body
        >
          <slot />
        </b-card>
      </b-card>

      <b-collapse v-if="collapse" v-model="visible">
        <b-card class="bg-light-gray border-0 mt-1" no-body>
          <slot name="collapse" />
        </b-card>
      </b-collapse>
    </MatchMedia>
  </div>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries';

export default {
  name: 'UiCardListItem',
  components: { MatchMedia },
  props: {
    borderIndicator: { type: Boolean, default: false },
    borderIndicatorColor: { type: String, default: '#e2e2e2' },
    collapse: { type: Boolean, default: false },
    borderedDesign: { type: Boolean, default: false },
  },
  data: () => ({
    visible: false,
  }),
};
</script>

<style lang="scss" scoped>
$border-indicator-size: 0.25rem;
$collapse-toggler-padding: 0.25rem;

.ui-card-list-item {
  &.ui-card-list-item--border-indicator {
    border-left: $border-indicator-size solid !important;
  }

  &.ui-card-list-item--collapse {
    .ui-card-list-item__container {
      margin-left: 1.5rem;
    }

    .ui-card-list-item--collapse__toggler {
      box-shadow: 0.3rem 0 0.3rem -2px rgb(0 0 0 / 15%) !important;
      cursor: pointer;

      left: 0;
      position: absolute;
      top: 0;

      height: 100%;
      padding: $collapse-toggler-padding;

      .ui-card-list-item--collapse__toggler__caret {
        height: 100%;

        padding-left: 0.25rem;
        padding-right: 0.25rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.ui-card-list-item--bordered {
    padding: 0.5rem;

    &.ui-card-list-item--collapse {
      .ui-card-list-item__container {
        margin-left: 2.5rem;
      }

      .ui-card-list-item--collapse__toggler {
        padding: 0.5rem;
      }
    }
  }

  &.ui-card-list-item--mobile {
    &.ui-card-list-item--border-indicator {
      border-left: none !important;
      border-top: $border-indicator-size solid !important;
    }

    &.ui-card-list-item--collapse {
      .ui-card-list-item__container {
        margin-left: 0 !important;
        margin-bottom: 2.5rem !important;
      }

      .ui-card-list-item--collapse__toggler {
        box-shadow: 0 -2px 0.3rem 0 rgb(0 0 0 / 15%) !important;

        top: unset;
        bottom: 0;

        height: unset;
        width: 100%;

        .ui-card-list-item--collapse__toggler__caret {
          width: 100%;

          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MatchMedia',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_c('b-card',{staticClass:"ui-card-list-item bg-gray border-0",class:[
        {
          'ui-card-list-item--border-indicator': _vm.borderIndicator,
          'ui-card-list-item--collapse': _vm.collapse,
          'ui-card-list-item--mobile': mobile,
          'ui-card-list-item--bordered': _vm.borderedDesign,
        } ],style:({
        'border-color': _vm.borderIndicator
          ? (_vm.borderIndicatorColor + " !important")
          : 'none',
      }),attrs:{"no-body":_vm.borderedDesign}},[(_vm.collapse)?_c('div',{staticClass:"ui-card-list-item--collapse__toggler",on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('div',{staticClass:"ui-card-list-item--collapse__toggler__caret",class:{ 'bg-white': _vm.borderedDesign }},[_c('i',{class:[
              'far',
              ("fa-" + (_vm.visible
                  ? ("chevron-circle-" + (mobile ? 'up' : 'down'))
                  : ("chevron-circle-" + (mobile ? 'down' : 'right')))) ],style:({ color: _vm.visible ? '#2db782' : '#ff7058' })})])]):_vm._e(),_c('b-card',{staticClass:"ui-card-list-item__container border-0",class:[_vm.borderedDesign ? 'bg-white p-3' : 'bg-transparent'],attrs:{"no-body":""}},[_vm._t("default")],2)],1),(_vm.collapse)?_c('b-collapse',{model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card',{staticClass:"bg-light-gray border-0 mt-1",attrs:{"no-body":""}},[_vm._t("collapse")],2)],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }